import { Link } from 'react-router-dom';
import '../styles/productsHome.scss';
import { useDocuments, useProducts } from '@wbsuite/react-hooks';
import { useEffect, useState } from 'react';
import noFoto from '../assets/noFoto.png';
import { ProductCard } from './products/ProductCard';

export const ProductsHome = () => {
  const { list: listProducts } = useProducts();
  const [ haghlightedList, setHaghlightedList ] = useState([]);
  const {  getPublicUrl } = useDocuments() as any ;

  useEffect(() =>{
    listProducts()
    .then((l) =>{
      setHaghlightedList(l.data)
      console.log("🚀 ~ l.data:", l.data)
    })
  }, [])

  const highlitedProducts = haghlightedList.filter((h: any) => h.highlighted === true );
  console.log("🚀 ~ highlitedProducts:", highlitedProducts)

    return (
        <div className='product-favorites'>
          <div className='product-favorites-container'>
            <div className='title-section'>
              <h2>Productos Plásticos de Calidad</h2> 
             <p className='span'>Descubre nuestra amplia gama de soluciones plásticas innovadoras y confiables</p>
            </div>
            <div className='enpa-grid'>
              {highlitedProducts.map((c: any) => <ProductCard 
              productName={c.name?.es}  productcImg={c.gallery[0]?.document ? getPublicUrl(c.gallery[0].document) : noFoto} productId={c._id}/>)}            
             </div>
             <div className='link-products'>
               <Link to='/categorias' className='general-button'>Ver todos los productos</Link>
             </div>
          </div>
        </div>
      )
    
}