import { NavBar } from '../components/NavBar';
import '../styles/detail.scss';
import { Form } from '../components/contact/Form';
import { Footer } from '../components/Footer';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useDocuments, useProducts } from '@wbsuite/react-hooks';
import noFoto from '../assets/noFoto.png';
// @ts-ignore
import { HashLink as Link } from 'react-router-hash-link';

export const Detail = () => {
	window.scrollTo(0, 0);

	const { productId } = useParams<{ productId: string }>();
	const { get } = useProducts();
	const [product, setProduct] = useState<any>(null);
	console.log("🚀 ~ product:", product)

	const { getPublicUrl } = useDocuments() as any;

	useEffect(() => {
		get(productId as string).then(filteredProduct => {
			setProduct(filteredProduct.data || []);
		});
	}, [productId]);

	const fichaTecnicaDocument = product?.gallery?.[1]?.document;
	const fichaTecnicaUrl = fichaTecnicaDocument ? getPublicUrl(fichaTecnicaDocument) : '';
	const nameFichaTecnica = fichaTecnicaDocument?.file?.originalname || 'ficha_tecnica.pdf';

	const handleDownload = async (url: string, filename: string) => {
		const response = await fetch(url);
		const blob = await response.blob();
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div>
			<NavBar />
			<div className='detail-page'>
				<div className='product-info'>
					<div className='image'>
						<img
							src={product?.gallery?.[0]?.document ? getPublicUrl(product.gallery[0].document) : noFoto}
							alt="Imagen del producto"
						/>
					</div>
					<div className='info'>
						<h2>{product?.name?.es}</h2>
						<h3>
							<b>Categoría:</b> {product?.categories?.[0]?.name?.es ?? 'Sin categoría'}
						</h3>
						<p className='description' dangerouslySetInnerHTML={{ __html: product?.description?.es || '' }}></p>
						<div className='detail-btn-container'>
							<Link to={`/detalle/${product?._id}/#p-form`} smooth className='detail-button general-button'>
								Realizar Consulta
							</Link>
							{fichaTecnicaUrl && (
								<button
									className="detail-button general-button download-btn"
									onClick={() => handleDownload(fichaTecnicaUrl, nameFichaTecnica)}
								>
									Descargar Ficha Técnica
								</button>
							)}
						</div>
					</div>
				</div>

				<div className='product-contact'>
					{!product?.variants?.length ? (
						<div className='measures caracteristicas'>
							<h2>Características</h2>
							<div className='measure-box'>
								{product?.attributes?.map((p: any) => (
									<div className='m-data' key={p._id}>
										<p><b>{p.attribute?.name?.es ?? p.name?.es}</b></p>
										<p>{p.value?.es ?? p.value}</p>
									</div>
								))}
							</div>
						</div>
					) : (
						<div className='measures types'>
							<h2>Tipos</h2>
							<div className='measure-box'>
							{product?.variants?.map((t: any) => {
								const v = t.product ?? t;
								const fichaTecnicaDocument = v?.gallery?.[1]?.document;
    							const fichaTecnicaUrl = fichaTecnicaDocument ? getPublicUrl(fichaTecnicaDocument) : '';
    							const nameFichaTecnica = fichaTecnicaDocument?.file?.originalname || 'ficha_tecnica.pdf';
								return (
									<div className='m-data' key={v._id}>
										<p><b>{v.name?.es}</b></p>
										<div className='product-data'>
											{v?.attributes?.map((p: any, i: number) => (
												<div key={i}>
													<p>{p.value?.es ?? p.value}</p>
													<p>{p.attribute?.name?.es}</p>
												</div>
											))}
										</div>
										{fichaTecnicaUrl && (
                <button
                    className="link-ficha-tecnica"
                    onClick={() => handleDownload(fichaTecnicaUrl, nameFichaTecnica)}
                >
            		Descargar Ficha Técnica
                </button>
            )}
									</div>
								);
							})}
							</div>
						</div>
					)}

					<div className='product-form'>
						<div id='p-form'></div>
						<h2>Realizá tu consulta</h2>
						<Form />
					</div>
				</div>
			</div>

			<div className='banner'>
				<span>Excelencia en Soluciones Plásticas</span>
				<h2>Descubre nuestra amplia gama de productos sustentables</h2>
				<div className='banner-link'>
					<Link to='/categorias' className='b-button general-button'>
						Ver Todas
					</Link>
				</div>
			</div>

			<Footer />
		</div>
	);
};
